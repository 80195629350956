import { useEffect, useState } from "react";
import "./App.css";
import API from "./variables/apiEndPoint";
import Home from "./pages/Home/Home";
function App() {
	

	return (
		<div>
			<Home />
		</div>
	);
}

export default App;
